.TeamCreateModal.ui.modal > .header:not(.ui) {
  font-size: 24px;
  line-height: 36px;
}

.TeamCreateModal.ui.modal > .header {
  /* Using !important to override Semantic !important values */
  padding: 24px !important;
  padding-bottom: 0px !important;
}

.TeamCreateModal.ui.modal > .header:not(.ui),
.TeamCreateModal.ui.modal > .header {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
}

.TeamCreateModal.ui.modal > .header:not(.ui) > p,
.TeamCreateModal.ui.modal > .header > p {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 8px;
}

.TeamCreateModal.ui.modal > .content {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
}

.TeamCreateModal.ui.modal > .content > .ui.form {
    margin-top: 22px;
}