.ui.modal .pluggy.alert {
  margin-left: 0;
}

.ui.modal .actions > .pluggy.ui.button {
  margin-left: 0.75em;
}

.ui.modal {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.ui.modal,
.ui.modal .header,
.ui.modal .content,
.ui.modal .actions {
  border-radius: 4px;
}

.ui.dimmer {
  background-color: var(--grey-700-transparent-05);
}

.ui.modal > .actions {
  border-top: 1px solid var(--grey-light);
}

.ui.modal > i.icon {
  color: var(--grey-900);
  /* Using !important to override Semantic !important values */
  top: 0.5rem !important;
  right: 0.5rem !important;
}

.ui.modal > .Icon.CloseIcon {
  top: 34px !important;
  padding: 0;
  right: 27px !important;
  height: 14px;
  width: 14px;
}

.ui.modal > .header:not(.ui) {
  font-size: 24px;
  line-height: 36px;
}

.ui.modal > .header {
  /* Using !important to override Semantic !important values */
  padding: 24px !important;
  padding-bottom: 0px !important;
}

.ui.modal > .content {
  /* Using !important to override Semantic !important value */
  padding: 24px !important;
  font-size: 14px;
}

.ui.modal > .content > .ui.form {
  margin-top: 22px;
}

.ui.modal > .actions {
  padding: 24px;
}

.ui.modal > .actions > .button {
  margin-left: 24px;
}

.ui.modal > .actions > .button.fluid {
  margin: 0;
}

.ui.modal .dropdown__menu {
  z-index: 100;
}
