.ui.dimmer .GrantAccessModal .ui.loader:before {
  border-color: var(--secondary);
}

.ui.dimmer .GrantAccessModal .ui.loader:after {
  border-color: var(--primary) transparent transparent;
}

.GrantAccessModal.inverted.ui.modal > .header,
.GrantAccessModal.inverted.ui.modal > .content,
.GrantAccessModal.inverted.ui.modal .ui.header,
.GrantAccessModal.inverted.ui.modal .ui.button {
  background: inherit;
  color: #fff;
}
.GrantAccessModal.ui.modal.inverted .GoBackIcon {
  fill: #fff;
}

.GrantAccessModal.ui.modal > .header .title {
  color: var(--title-text);
}

.GrantAccessModal.ui.modal > .header {
  border-bottom: 1px solid var(--divider);
  text-align: center;
  font-weight: 500;
}

.GrantAccessModal.ui.modal .GoBackIcon {
  vertical-align: middle;
  height: 30px;
  cursor: pointer;
  position: absolute;
  left: 6px;
  top: 13px;
}

.GrantAccessModal .loader-container {
  position: relative;
  min-height: 350px;
}

.GrantAccessModal .ui.form .ui.primary.button {
  margin-top: 15px;
  width: 100%;
}