.EmailsInput .emails-input-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
}

.EmailsInput .emails-input-header > span {
  line-height: 24px;
  margin: 0;
}

.EmailsInput .add-item-row {
  display: flex;
  flex-direction: row;
}

.EmailsInput .add-item-row .delete-item {
  margin-top: 8px;
  margin-left: 20px;
}
