.ItemListItem .title {
  color: var(--title-text);
  font-weight: 400;
}

.ItemListItem .item-actions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.ItemListItem .item-actions > .pluggy.ui.button {
  align-self: flex-end;
  margin-top: 8px;
}

.ItemListItem .pluggy.Loader.item-polling {
  display: inline-block;
  position: absolute;
  margin-left: 8px;
}
