.UserListItem .column .title {
  font-weight: 700;
}

.UserListItem .column .id {
  color: rgba(0, 0, 0, 0.4);
}

.UserListItem .ListSection.Teams {
  padding-bottom: 0;
  border-bottom: 0;
}

.UserListItem .ListSection.Teams .ui.header:last-child {
  font-size: 1.5rem;
}

.UserListItem .impersonate-link-popup-wrapper {
  font-size: 14px;
}

.UserListItem .column .is-verified {
  margin-top: 4px;
  font-style: italic;
  font-size: 0.875rem;
}
