.IncidentItem .ui.header {
  font-weight: 700;
}

.IncidentItem .incident-update {
  margin-top: 8px;
}

.IncidentItem .incident-actions {
  display: flex;
  justify-content: flex-end;
}
