.ConnectorsPage {
  position: relative;
}

.ConnectorsPage .Section:last-child {
  border: none;
}

.ConnectorsPage .ListSection {
  border: none;
}

.ConnectorsPage .connectors-actions {
  position: absolute;
  right: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  z-index: 1;
}
