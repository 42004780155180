.ActionItem {
  padding: 22px;
  height: 124px;
  margin-right: 16px;
  margin-bottom: 16px;
  /* White */
  background: #ffffff;
  /* Grey/100 */
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;

  flex-shrink: 1;
  flex-grow: 0;
  /* split in 3 columns */
  flex-basis: 31%;
}

.ActionItem:hover {
  border-color: #303030;
  cursor: pointer;
}
