.ui.dimmer .TeamUpdateModal .ui.loader:before {
  border-color: var(--secondary);
}

.ui.dimmer .TeamUpdateModal .ui.loader:after {
  border-color: var(--primary) transparent transparent;
}

.TeamUpdateModal.inverted.ui.modal > .header,
.TeamUpdateModal.inverted.ui.modal > .content,
.TeamUpdateModal.inverted.ui.modal .ui.header,
.TeamUpdateModal.inverted.ui.modal .ui.button {
  background: inherit;
  color: #fff;
}

.TeamUpdateModal.ui.modal.inverted .GoBackIcon {
  fill: #fff;
}

.TeamUpdateModal.ui.modal > .header:not(.ui) .title,
.TeamUpdateModal.ui.modal > .header:not(.ui) .subtitle,
.TeamUpdateModal.ui.modal > .header:not(.ui) {
  color: var(--title-text);
  font-weight: 400;
}

.TeamUpdateModal.ui.modal > .header:not(.ui) .subtitle {
  margin-bottom: 24px;
}

.TeamUpdateModal.ui.modal,
.TeamUpdateModal.ui.modal > .header,
.TeamUpdateModal.ui.modal .ui.header {
  font-family: var(--font-family);
}

.TeamUpdateModal.ui.modal > .header {
  border-bottom: none;
  margin-bottom: 8px;
}

.TeamUpdateModal.ui.modal > .header > .title {
  font-family: var(--secondary-font-family);
}

.TeamUpdateModal.ui.modal .header .subtitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.TeamUpdateModal .team-details {
  background-color: var(--grey-50);
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
}

.TeamUpdateModal .team-details > p {
  color: var(--grey-900);
}

.TeamUpdateModal .team-details > p > strong {
  font-weight: 500;
}

.TeamUpdateModal .team-details > p:not(:last-child) {
  margin-bottom: 8px;
}

.TeamUpdateModal .loader-container {
  position: relative;
  min-height: 350px;
}

.TeamUpdateModal .ui.form .ui.primary.button {
  margin-top: 15px;
  width: 100%;
}

.TeamUpdateModal .form span.label {
  color: #000;
  font-size: 14px;
}

.TeamUpdateModal .team-subscription-form.ui.form .fields {
  margin: 0;
}

.TeamUpdateModal .team-subscription-form .ui.header {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 0;
}

.TeamUpdateModal .team-subscription-form .ui.header.motivation-header .title {
  font-size: 16px;
  margin-bottom: 8px;
}

.TeamUpdateModal .team-subscription-form .ui.header.go-to-production-header {
  display: flex;
}
.TeamUpdateModal
  .team-subscription-form
  .ui.header.go-to-production-header
  > a.link {
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
}

.TeamUpdateModal .team-subscription-form .free-trial-alert.pluggy.alert {
  margin-bottom: 4px;
}

.TeamUpdateModal .ui.form .fields {
  flex-direction: column;
}

.TeamUpdateModal .ui.form .fields .field {
  margin-top: 4px;
  margin-bottom: 4px;
}

.TeamUpdateModal .ui.form .fields .field.product-field {
  display: flex;
  margin-top: 6px;
  margin-bottom: 6px;
  align-items: center;
}

.TeamUpdateModal .product-plan-tag {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 8px;
  border-radius: 10px;
  margin-left: 8px;
  margin-right: 8px;
  color: var(--white);
}

.TeamUpdateModal .product-plan-tag.Free {
  background-color: var(--free-subscription-tag-color);
}

.TeamUpdateModal .product-plan-tag.Full {
  background-color: var(--pro-subscription-tag-color);
}

.TeamUpdateModal .product-plan-tag.Extra {
  background-color: var(--extra-tag-color);
}

.TeamUpdateModal .ui.form .pluggy.checkbox > label {
  padding-left: 1.5rem;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.TeamUpdateModal
  .ui.form
  .ui.disabled.radio.checkbox.pluggy
  input[type='radio']
  ~ label {
  /* TODO: this should be in pluggyai/ui Radio component styles */
  color: var(--checkbox-disabled-font-color);
}

.TeamUpdateModal
  .ui.form
  .ui.disabled.radio.checkbox.pluggy
  input[type='radio']
  ~ label {
  /* TODO: this should be in pluggyai/ui Radio component styles */
  color: var(--checkbox-disabled-font-color);
}

.TeamUpdateModal .items-frequency-update-option {
  display: flex;
  flex-direction: column;
}

.TeamUpdateModal
  .items-frequency-update-option
  > .items-frequency-update-option-dropdown.field.pluggy {
  width: 100%;
  margin-top: 16px;
}

.TeamUpdateModal
  .items-frequency-update-option
  > .field.pluggy
  .pluggy.Dropdown
  ~ .text-container {
  display: none;
}

.TeamUpdateModal .items-frequency-help-message {
  font-size: 14px;
  color: var(--grey-500);
}

.TeamUpdateModal .free-trial-message-container {
  margin-left: 12px;
}

.TeamUpdateModal .Dropdown.pluggy .dropdown__control {
  padding-top: 0;
  padding-bottom: 0;
}

.TeamUpdateModal #iniciador-credentials-label {
  font-weight: normal;
  font-size: 14px;
}

.TeamUpdateModal #iniciador-credentials-input {
  margin-top: 8px;
  cursor: pointer;
}

.TeamUpdateModal #iniciador-credentials-input:hover,
.TeamUpdateModal #iniciador-credentials-input:active {
  background-color: var(--grey-50);
  border-color: var(--grey-100);
  outline: none;
}

.TeamUpdateModal #iniciador-credentials-input::file-selector-button {
  display: none;
}

.TeamUpdateModal .payment-pricing.ui.grid {
  margin: 0;
}

.TeamUpdateModal .payment-pricing.ui.grid > .row {
  padding-bottom: 0px;
  padding-top: 0px;
}
