:root {
  /* fonts */
  --font-size: 1rem;
  --border-color: #e0e0e0;
}

html {
  font-size: 16px;

  /* Set width to 100vw to prevent scrollbar from reducing from available width */
  width: 100vw;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  background: var(--background);
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  color: var(--title-text);
}

p {
  color: var(--text);
}

a,
.link {
  color: var(--primary);
  cursor: pointer;
}
a:hover,
.link:hover {
  color: var(--primary-hover);
}

.text {
  color: var(--text);
}

.text:hover {
  color: var(--text);
}

.secondary-text {
  color: var(--secondary-text);
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
