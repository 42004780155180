.ItemSearchSection .item-actions {
  display: flex;
  align-items: center;
}

.ItemSearchSection > .item-search-form.ui.form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ItemSearchSection > .item-search-form.ui.form > .pluggy.search-box {
  flex: 1;
  flex-basis: 370px;
  flex-grow: 0;
}

.ItemSearchSection .item-search-form .error-message {
  margin: 8px;
  color: var(--error);

  height: 22px;
  min-width: 100px;
}
