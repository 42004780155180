.TeamListItem .column .title {
  font-weight: 700;
}

.TeamListItem .column .id {
  color: rgba(0, 0, 0, 0.4);
}

.TeamListItem .impersonate-link-popup-wrapper {
  font-size: 14px;
}
