.IncidentCreateForm .dropdown__menu {
  /* Necessary fix to ensure the select dropdown menu stays on top of the rest of items in the form. TODO: move to pluggy/ui */
  z-index: 10;
}

.IncidentCreateForm .field-container > h4 {
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.IncidentCreateForm .current-incident .incident-update {
  margin-top: 8px;
}

.IncidentCreateForm .current-incident {
  margin-bottom: 12px;
}

.IncidentCreateForm .field-container p.message {
  font-size: 14px;
}
