.MoveApplicationToProductionModal.ui.modal > .header .title {
  font-weight: 500;
  color: var(--gray-900);
}

.MoveApplicationToProductionModal.ui.modal > .header .title span {
  color: black;
  font-weight: 700;
}

.modal-move-application-to-production-actions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}
