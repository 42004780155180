.ListSection .list-section-loader {
  min-height: 200px;
  position: relative;
}

.ListSection .ui.loader.list-section-loader {
  top: 120px;
}

.ListSection .list-header-container {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.ListSection .ui.header {
  font-weight: 500;
  margin: 0;
}

.ListSection .list-header-container .ui.button.medium,
.ListSection .list-header-container .ui.button,
.ListSection .list-header-container .ui.input.search-input {
  margin: auto;
  margin-right: 0;
}

.ListSection .list {
  width: 100%;
}

.ListSection .list-content {
  margin-top: 16px;
}

.ListSection .list-empty {
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
}

.ListSection .list-empty .ui.button {
  margin-top: 30px;
}