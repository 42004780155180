.ConnectorItem {
}

.ConnectorItem .connector-actions {
  display: flex;
  flex-direction: column;
}

.ConnectorItem .connector-actions .connector-status-action {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.ConnectorItem .connector-actions .connector-incidents-action {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-top: 12px;
  width: 100%;
  padding-left: 88px;
}

.ConnectorItem .connector-actions .connector-component-status {
  margin-top: 8px;
}

.ConnectorItem
  .connector-actions
  .connector-status-action
  .connector-status.field.pluggy,
.ConnectorItem .connector-actions .connector-incidents-action > div,
.ConnectorItem
  .connector-actions
  .connector-incidents-action
  > .ui.pluggy.button {
  margin-left: 12px;
}

.ConnectorItem
  .connector-actions
  .connector-status.field.pluggy
  > .text-container {
  display: none;
}

.ConnectorItem .connector-actions .connector-status-action .pluggy.ui.button,
.pluggy.ui.button.secondary {
  width: 180px;
}
