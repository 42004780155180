.ApplicationShareLinkCreateForm .custom-url-container {
  display: flex;
  white-space: nowrap;
}

.ApplicationShareLinkCreateForm
  .custom-url-container
  .custom-url-container-part {
  padding-top: 10px;
  margin-right: 8px;
}

.ApplicationShareLinkCreateForm
  .custom-url-container
  .custom-url-container-part:not(:first-child) {
  margin-left: 8px;
}

.ApplicationShareLinkCreateForm .field-container > h4 {
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.ApplicationShareLinkCreateForm .dropdown__menu {
  /* Necessary fix to ensure the select dropdown menu stays on top of the rest of items in the form. TODO: move to pluggy/ui */
  z-index: 10;
}

.ApplicationShareLinkCreateForm .expires-at-field-content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.ApplicationShareLinkCreateForm .expires-at-field-content > .ui.radio {
  margin-top: 12px;
  margin-bottom: 12px;
}

.ApplicationShareLinkCreateForm .expires-at-field-content .date-picker {
  flex-basis: 200px;
  flex-shrink: 1;
  flex-grow: 0.5;
}

.ApplicationShareLinkCreateForm .ui.input.date-picker.error > input {
  border-color: var(--error);
}

.ApplicationShareLinkCreateForm .expires-at-field-content > div,
.ApplicationShareLinkCreateForm .expires-at-field-content .date-picker {
  margin-right: 16px;
}

.ApplicationShareLinkCreateForm
  .expires-at-field-content
  .expires-at-date-picker {
  display: flex;
  align-items: center;
  flex: 1;
}

.ApplicationShareLinkCreateForm .expires-at-field-content .expires-at-text {
  flex: 1;
  flex-basis: 180px;
  flex-grow: 0;
}
