body .ui.dropdown,
body .ui.active.selection.dropdown,
body .ui.simple.active.dropdown,
body .ui.simple.dropdown:hover,
body .ui.dropdown .menu,
body .ui.menu .dropdown.item .menu {
  /* Using !important to override Semantic !important values */
  border-radius: 4px !important;
}

body .ui.selection.dropdown .menu,
body .ui.dropdown .menu {
  margin-top: 8px;
}

body .ui.dropdown .menu .item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

body .ui.dropdown .menu .item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-color: var(--grey-400);
}

body .ui.dropdown .menu > .item,
body .ui.menu .ui.dropdown .menu > .item,
body .ui.menu .ui.dropdown .menu > .item.selected {
  /* Using important to override the !important in SemanticUI */
  padding-top: 13px !important;
  padding-left: 16px !important;
  padding-bottom: 13px !important;
}

body .ui.disabled.dropdown {
  /* Using important to override the !important in SemanticUI */
  cursor: not-allowed;
  pointer-events: all !important;
}

body .ui.selection.dropdown:focus,
body .ui.selection.active:focus .menu,
body .ui.selection.active:active .menu,
body .ui.selection.active:hover .menu,
body .ui.selection.active .menu,
body .ui.selection.active.dropdown .menu,
body .ui.selection.active.dropdown:hover .menu,
body .ui.selection.active.dropdown:focus .menu,
body .ui.selection.dropdown:focus,
body .ui.dropdown .menu,
body .ui.selection.active.dropdown:hover,
body .ui.selection.active.dropdown {
  border-color: var(--grey-400);
}
