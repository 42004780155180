.ui.dimmer .TeamUpdateTermsModal .ui.loader:before {
  border-color: var(--secondary);
}

.ui.dimmer .TeamUpdateTermsModal .ui.loader:after {
  border-color: var(--primary) transparent transparent;
}

.TeamUpdateTermsModal.inverted.ui.modal > .header,
.TeamUpdateTermsModal.inverted.ui.modal > .content,
.TeamUpdateTermsModal.inverted.ui.modal .ui.header,
.TeamUpdateTermsModal.inverted.ui.modal .ui.button {
  background: inherit;
  color: #fff;
}

.TeamUpdateTermsModal.ui.modal > .header:not(.ui) .title,
.TeamUpdateTermsModal.ui.modal > .header:not(.ui) > .subtitle,
.TeamUpdateTermsModal.ui.modal > .header:not(.ui) {
  color: var(--title-text);
  font-weight: 400;
}

.TeamUpdateTermsModal.ui.modal > .header:not(.ui) {
  border-bottom: none;
  font-family: 'Poppins';
}

.TeamUpdateTermsModal.ui.modal > .header:not(.ui) > .subtitle {
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.TeamUpdateTermsModal .loader-container {
  position: relative;
  min-height: 350px;
}

.TeamUpdateTermsModal .ui.form .ui.primary.button {
  margin-top: 15px;
  width: 100%;
}

.TeamUpdateTermsModal .form span.label {
  font-family: 'Work Sans';
  color: #000;
}

.TeamUpdateTermsModal .ui.form .fields {
  flex-direction: column;
}

.TeamUpdateTermsModal .ui.form .fields .field {
  margin-top: 4px;
  margin-bottom: 4px;
}

.TeamUpdateTermsModal .ui.form .pluggy.checkbox > label {
  padding-left: 1.5rem;
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.TeamUpdateTermsModal
  .ui.form
  .ui.disabled.radio.checkbox.pluggy
  input[type='radio']
  ~ label {
  /* TODO: this should be in pluggyai/ui Radio component styles */
  color: var(--checkbox-disabled-font-color);
}

.TeamUpdateTermsModal
  .ui.form
  .ui.disabled.radio.checkbox.pluggy
  input[type='radio']
  ~ label {
  /* TODO: this should be in pluggyai/ui Radio component styles */
  color: var(--checkbox-disabled-font-color);
}
