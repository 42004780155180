.TextInputOptional {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.TextInputOptional > .ui.radio {
  margin-top: 12px;
  margin-bottom: 12px;
}

.TextInputOptional > div {
  margin-right: 16px;
}

.TextInputOptional .input-container {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  flex-basis: 100%;
  margin-top: 12px;
  margin-bottom: 12px;

  /* TODO: move textarea component to @pluggyai/ui https://pluggy.atlassian.net/browse/DVT-412 */
  padding: 12px 10px 0;

  border: 1px solid var(--grey-200);
  border-radius: 4px;
}

.TextInputOptional .input-container:hover {
  border: solid 1px var(--input-hover-border);
}

.TextInputOptional .input-container:focus-within {
  border-color: var(--pink-300);
}

.TextInputOptional .input-container .input {
  padding: 0;
  border: none;
  min-height: 80px;
  max-height: 1000px;

  font-size: 14px;
  line-height: 16px;
  font-family: var(--font-family);
}

.TextInputOptional .input-container .input:focus::placeholder {
  --input-color: var(--grey-200);
}

/* label */
.TextInputOptional .input-container .input ~ .label {
  position: absolute;
  top: 12px;
  left: 16px;

  font-size: 14px;
  transition: 0.2s ease all;

  font-family: var(--font-family);
}

.TextInputOptional .input-container .input:focus ~ .label,
.TextInputOptional .input-container .input.filled ~ .label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: var(--background);
  padding: 2px 4px;
  font-size: 12px;
}
