.FreeTrialField.field {
  display: flex;
  align-content: center;
  align-items: center;
}

.FreeTrialField.field span {
  font-style: italic;
  font-size: 14px;
  margin-right: 8px;
  margin-left: 8px;
}

.TeamUpdateModal
  .FreeTrialField.field
  > .expires-at-date-picker
  > .ui.input.date-picker,
.FreeTrialField.field > .expires-at-date-picker > .ui.input.date-picker {
  width: auto;
}

.FreeTrialField.field > .ui.Radio:last-child {
  margin-left: auto;
  margin-right: 16px;
}

.TrialRadio-Popup.ui.popup,
.TrialRadio-Popup.ui.popup > div,
.TrialRadio-Popup.ui.top.popup:before {
  margin: 0;
  background-color: var(--warning-background);
}

.TrialRadio-Popup.ui.popup {
  padding: 0;
  max-width: 350px;
}
