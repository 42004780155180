.Icon:hover path {
  fill: var(--icon-hover);
}

.Icon path {
  fill: var(--icon-active);
}

.Icon.disabled path {
  fill: var(--icon-disabled);
}
