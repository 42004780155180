.OverviewPage .Section:last-child {
  border: none;
}

.OverviewPage .ListSection {
  border: none;
}

.OverviewPage .actions-list {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  .OverviewPage .actions-list {
    flex-direction: column;
  }
}
