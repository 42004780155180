.FreeTrialExpiresAtMessage:not(.expired) {
  font-weight: bold;
}

.FreeTrialExpiresAtMessage.expired {
  font-style: italic;
}

.FreeTrialExpiresAtMessage.expires-soon {
  color: var(--danger);
}

.FreeTrialExpiresAtMessage.expired.expired-recently {
  color: var(--error);
}
