
/* sizes */

.ui.button.mini {
  padding: 3px 10px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  min-width: 60px;
}

.ui.button.tiny {
  padding: 4px 12px;
  border-radius: 3px;
  font-size: 11px;
  line-height: 16px;
  min-width: 80px;
}

.ui.button.small {
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 18px;
  min-width: 128px;
}

.ui.button.medium,
.ui.button {
  padding: 13px 32px;
  border-radius: 6px;
  font-size: 13px;
  line-height: 18px;
  min-width: 128px;
}

.ui.button.large,
.ui.button.huge,
.ui.button.massive {
  padding: 14px 40px;
  border-radius: 8px;
  font-size: 15px;
  line-height: 24px;
  min-width: 160px;
}

/* disabled */

.ui.button.disabled {
  opacity: 0.5;
}

/* primary and secondary */

.ui.button,
.ui.button.secondary {
  background-color: var(--secondary);
  color: var(--text-on-secondary);
}

.ui.button.primary {
  background-color: var(--primary);
  color: var(--text-on-primary);
}

.ui.button:hover,
.ui.button.secondary:hover,
.ui.button:active,
.ui.button.secondary:active,
.ui.button:focus,
.ui.button.secondary:focus {
  background-color: var(--secondary-hover);
  color: var(--text-on-secondary);
}

.ui.button.primary:hover,
.ui.button.primary:active,
.ui.button.primary:focus {
  background-color: var(--primary-hover);
  color: var(--text-on-primary);
}

/* links */

.ui.button a,
.ui.button.secondary a,
.ui.button:hover a,
.ui.button.secondary:hover a,
.ui.button:active a,
.ui.button.secondary:active a,
.ui.button:focus a,
.ui.button.secondary:focus a {
  color: var(--text-on-secondary);
}

.ui.button.primary a {
  color: var(--text-on-primary);
}

/* basic */

.ui.button.basic {
  text-transform: uppercase;
  color: var(
    --primary
  ) !important; /* this !important is due to an important in semantic-ui-css */
  font-weight: 500;
  box-shadow: none;
  padding: 8px;
  min-width: auto;
}

.ui.button.basic a {
  color: var(
    --primary
  ) !important; /* this !important is due to an important in semantic-ui-css */
}

.ui.button.basic:hover,
.ui.button.basic:focus,
.ui.button.basic:active {
  color: var(
    --primary
  ) !important; /* this !important is due to an important in semantic-ui-css */
  box-shadow: none;
  background: transparent !important; /* this !important is due to an important in semantic-ui-css */
  transform: translateY(0px);
}

.ui.button.basic:hover a,
.ui.button.basic:focus a,
.ui.button.basic:active a {
  color: var(
    --primary-hover
  ) !important; /* this !important is due to an important in semantic-ui-css */
}

/* button group */

.ui.button.inverted {
  background-color: transparent;
  color: var(--text);
  border: 1px solid var(--text);
  box-shadow: none !important;
}

.ui.button.inverted:hover {
  background-color: transparent;
  color: var(--text);
  border: 1px solid var(--text);
  box-shadow: none !important;
}

.ui.button.inverted.primary,
.ui.button.inverted.primary:active,
.ui.button.inverted.primary:focus {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: none !important;
}

.ui.button.inverted.primary:hover {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: none !important;
}

.ui.button.inverted.secondary,
.ui.button.inverted.secondary:active,
.ui.button.inverted.secondary:focus {
  background-color: transparent;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  box-shadow: none !important;
}

.ui.button.inverted.secondary:hover {
  background-color: transparent;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  box-shadow: none !important;
}
