:root {
  /* global */
  --background: #ffffff;
  --danger: #ffa900;
  --error: #ff0000;
  --divider: #e3e9ef;

  --grey-700-transparent-05: rgba(60, 60, 60, 0.5);

  /* text */
  --text: #7e7e7e;
  --secondary-text: #000;
  --title-text: #1f130f;
  --text-on-primary: #fff;
  --text-on-secondary: #fff;

  --free-subscription-tag-color: #7f5acc;
  --pro-subscription-tag-color: var(--green-500);
  --extra-tag-color: var(--grey-200);
}
