.Navbar.ui.menu,
.Navbar.ui.menu.fixed {
  /* Set width to 100vw to prevent scrollbar from reducing from available width */
  width: 100vw;
}

.Navbar.ui.menu .pluggy.button.link {
  min-width: unset;
}

.Navbar.ui.menu .item > i.dropdown.icon {
  margin: 0;
}

.Navbar.ui.menu .user-info .user-name {
  margin-right: 16px;
}

@media only screen and (max-width: 1000px) {
  /* Hide uesr name*/
  .Navbar.ui.menu .user-info .user-name {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  /* Hide logo */

  .Navbar.ui.menu:not(.vertical) .left.item,
  .Navbar.ui.menu:not(.vertical) :not(.dropdown) > .left.menu {
    display: none;
  }
}
